import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Classes } from "@blueprintjs/core";
import styles from "../../styles/InfoBlock.module.scss";
import { copyToClipboard } from "../pages/campaigns/utils/copyToClipBoard";
import { Checked, Copy } from "./LucideIcons";

const TextWithLabel = ({ loading, label, text, className, canCopy = false }) => {
  const [copied, setCopied] = useState(false);
  const classes = classNames({
    "col-12": !loading,
    "col-4": loading,
    [Classes.SKELETON]: loading,
  });

  return (
    <div className={classNames(styles.wrapper, className)}>
      <div
        style={{ display: "flex", justifyContent: "flex-start", width: "max-content", gap: "10px" }}
      >
        <div className={classNames(styles.label, classes)}>{label}</div>
        {canCopy && (
          <div
            style={{ paddingTop: "2px" }}
            onClick={async () => {
              setCopied(true);
              copyToClipboard(document.getElementById(label)).then(() => {
                setTimeout(() => {
                  setCopied(false);
                }, 2000);
              });
            }}
          >
            {copied ? <Checked /> : <Copy />}
          </div>
        )}
      </div>
      <div className={classNames("col-12", styles.textValue, classes)} id={label}>
        {text}
      </div>
    </div>
  );
};

TextWithLabel.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
};

export default TextWithLabel;
