/**
 * Copies the text content of a given parent element to the clipboard.
 *
 * @param {HTMLElement} ParentElement - The parent HTML element whose text content will be copied.
 */
export const copyToClipboard = async (ParentElement) => {
  if (ParentElement) {
    // Get only the text content (no HTML tags)
    const { textContent } = ParentElement;

    // Copy to clipboard using the Clipboard API
    await navigator.clipboard.writeText(textContent || "");
  }
};
