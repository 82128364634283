import React from "react";
import { isEmpty, groupBy } from "lodash";
import { Icons } from "prefab";
import { isSlateAdminCompanyUser } from "../../../../../utils";
import { getValidity, getName } from "../../utils";
import { CAMPAIGN_STATUSES } from "../../../../../constants";
import InfoBlock from "../../../../common/InfoBlock";
import styles from "../../../../../styles/Campaigns/Campaigns.module.scss";
import { targetGroupIcons } from "../../utils";
import CampaignActionDetails from "../../utils/CampaignActions";
import { renderCriteriaName } from "../../utils";

const { AddRoundedIcon, CancelRoundedIcon } = Icons;

const renderType = (data) => {
  const groupedType = groupBy(data, "type");
  return Object.keys(groupedType).map((key, index) => {
    return (
      <div key={index} className={styles.targetGroupType}>
        <div>{groupedType[key][0].typeDisplayName}</div>
        <div className={styles.criteria}>
          {groupedType[key].map(
            (c, i) =>
              c.criteria === "inclusions" && (
                <div key={i} className="flex align-center">
                  <AddRoundedIcon />
                  <div className={styles.typeName}>{renderCriteriaName(c)}</div>
                </div>
              )
          )}
          {groupedType[key].map(
            (c, i) =>
              c.criteria === "exclusions" && (
                <div key={i} className="flex align-center">
                  <CancelRoundedIcon />
                  <div className={styles.typeName}>{renderCriteriaName(c)}</div>
                </div>
              )
          )}
        </div>
      </div>
    );
  });
};
const renderTargetGroupTypes = (targetGroup) => {
  const groupedTarget = groupBy(targetGroup, "targetGroupType");
  return Object.keys(groupedTarget).map((key, index) => {
    return (
      <InfoBlock
        key={index}
        header={groupedTarget[key][0].targetGroupTypeDisplayName}
        headerIcon={targetGroupIcons(groupedTarget[key][0].targetGroupType)}
      >
        <div>{renderType(groupedTarget[key])}</div>
      </InfoBlock>
    );
  });
};

const TargetGroupDetails = (targetGroup, userData) => {
  const isAdminApp = isSlateAdminCompanyUser(userData);
  if (isEmpty(targetGroup)) return null;
  const inclusions =
    (targetGroup.criteria &&
      targetGroup.criteria.inclusions &&
      targetGroup.criteria.inclusions.map((data) => ({ ...data, criteria: "inclusions" }))) ||
    [];
  const exclusions =
    (targetGroup.criteria &&
      targetGroup.criteria.exclusions &&
      targetGroup.criteria.exclusions.map((data) => ({ ...data, criteria: "exclusions" }))) ||
    [];
  const targetGroupCriteria = [...inclusions, ...exclusions];
  return (
    <div>
      <InfoBlock
        header="Target Group Details"
        config={[
          {
            title: "Target Group Name & ID",
            value: getName(targetGroup, isAdminApp),
            size: {
              lg: 6,
            },
            canCopy: true,
          },

          {
            title: "Validity",
            value: getValidity(targetGroup),
            size: {
              lg: 6,
            },
          },
        ]}
      />
      {renderTargetGroupTypes(targetGroupCriteria)}
      {targetGroup.status === CAMPAIGN_STATUSES.PAUSED && (
        <CampaignActionDetails
          actionList={targetGroup.statusChanges}
          status={targetGroup.status}
          header="Target Group Pause Details"
        />
      )}
    </div>
  );
};

export default TargetGroupDetails;
