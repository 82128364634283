import axios from "axios";
import queryString from "query-string";
import { partial, pickBy, identity } from "lodash";
import { Position } from "@blueprintjs/core";
import * as Sentry from "@sentry/browser";
import { showToastWithPosition } from "../utils";

import {
  CORE_BASE_URL,
  MOVIEBUFF_BASE_URL,
  ROLES_SCOPES_BASE_URL,
  MOCK_SERVER_URL,
  REPORTS_BASE_URL,
  QW_THEATRE_MATCHER_BASE_URL,
} from "../config";
import { createFilterRequest } from "../utils";
import { DEFAULT_PAGE_SIZE } from "../constants";
import getCampaignTree from "./campaignTree";
import getBrandById from "./getBrandById";

const validateToken = (token, cs) =>
  axios.get(
    `/companies/userinfo?${queryString.stringify({
      cs: !!process.env.REACT_APP_IS_ADMIN_APP ? "SLATE_ADMIN" : "MEDIA_AGENCY",
    })}`,
    {
      baseURL: CORE_BASE_URL,
      headers: {
        Authorization: token,
      },
    }
  );

let core = axios.create({
  baseURL: CORE_BASE_URL,
});

const qw_csv = axios.create({
  baseURL: QW_THEATRE_MATCHER_BASE_URL,
});

const intialiseCore = (token) => {
  if (!token) {
    core = null;
  } else {
    core = axios.create({
      baseURL: CORE_BASE_URL,
      headers: {
        Authorization: token,
      },
    });

    ReportsServer = axios.create({
      baseURL: REPORTS_BASE_URL,
      headers: {
        Authorization: token,
      },
    });

    core.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error && error.response && error.response.status === 401) {
          window.location.href = "/login";
          showToastWithPosition("Toast.sessionExpired", false, 5000, Position.TOP_LEFT);
        } else if (error && error.response && error.response.status === 400) {
          Sentry.captureMessage("Bad API request");
          throw error;
        } else if (error && error.response && error.response.status === 403)
          window.location.href = "/unauthorized";
        else return Promise.reject(error);
      }
    );
  }
};
const moviebuff = axios.create({
  baseURL: MOVIEBUFF_BASE_URL,
  headers: {
    "x-api-key": "62d94122b834dafdefd9c597dece0cc15979eacb",
  },
});

const rolesScopes = axios.create({ baseURL: ROLES_SCOPES_BASE_URL });

const mockServer = axios.create({ baseURL: MOCK_SERVER_URL });

const createQueryParams = (args) => {
  const filteredParams = pickBy(args, identity);
  return queryString.stringify(filteredParams);
};
/** getList takes an axios instance , url  and required filters and returns an axios post request */
const getList = (axiosInstance, url, { filter = [], ps = DEFAULT_PAGE_SIZE, ...rest }) => {
  const { req: reqPayload, qp } = createFilterRequest(filter);
  const staticQueryParams = createQueryParams({ ps, ...rest, ...qp });
  let listUrl = `${url}/_list`;
  if (staticQueryParams) listUrl += `?${staticQueryParams}`;
  return axiosInstance.post(listUrl, reqPayload);
};
//new function getLists
const getLists = (axiosInstance, url, object, { filter = [], ps = DEFAULT_PAGE_SIZE, ...rest }) => {
  const { req: reqPayload, qp } = createFilterRequest(filter);
  const staticQueryParams = createQueryParams({ ps, ...rest, ...qp });
  let listUrl = `${url}/_list`;
  if (staticQueryParams) listUrl += `?${staticQueryParams}`;
  return axiosInstance.post(listUrl, { ...reqPayload, ...object });
};

const getNavCount = () => {}; // TODO: Update when count api is ready

// Logs
const getLogsByRef = (ref, ps, offset, sort, filter) =>
  getList(core, `logs/ref/${ref}`, { ps, offset, sort, filter });
const getLogsBySource = (sourceId, ps, offset, sort, filter) =>
  getList(core, `logs/source/${sourceId}`, { ps, offset, sort, filter });

// Admin List Management
const searchCertificates = (ps, offset, sort, filter) =>
  getList(core, `/certifications`, { ps, offset, sort, filter });
const searchNotificationTemplates = (ps, offset, sort, filter = []) =>
  getList(core, `/notification_templates`, { ps, offset, sort, filter });
const searchContentTypes = (ps, offset, sort, filter) =>
  getList(core, `/content_types`, { ps, offset, sort, filter });

const searchCpls = (value) => core.post(`/cpls/_list?q=${value}`);
const getReasonsList = (ps, offset, sort, filter = []) =>
  getList(core, `/reasons`, { ps, offset, sort, filter });
const getAllReasonsByType = (type) =>
  getList(core, `/reasons`, { type, ps: -1, sort: "reason:asc" });
const fetchReasonTypes = () => core.get(`/reasons/types`);
const createReason = (data) => core.post(`/reasons`, data);
const updateReason = (data) => core.put(`/reasons/${data.id}`, data);
const getReasonById = (id) => core.get(`/reasons/${id}`);

const searchCountries = (ps, offset, sort, filter, q) =>
  getList(core, `/countries`, { ps, offset, sort, filter, q });
const searchSegmentTypes = (companyId, ps, offset, sort, filter) =>
  getList(core, `/companies/${companyId}/segments`, { ps, offset, sort, filter });
const getCategoryLists = (ps, offset, sort, filter) =>
  getList(core, `/categories`, { ps, offset, sort, filter });
const fetchBillingCycles = (sort) => getList(core, `/billing_cycles`, { sort });
const fetchOrderRegions = () => core.get(`campaigns/order_regions`);
const fetchMediaList = (ps, offset, sort, filter) =>
  getList(core, `/media`, { ps, offset, sort, filter });
const fetchProductIdentificationNumberTypeList = (ps, offset, sort = "", filter = {}) =>
  getList(core, `/product_identification_number_types`, { ps, offset, sort, filter });
const getTimesOfDay = (sort) => core.get(`/times_of_day?${createQueryParams({ sort })}`);
const getTimesOfDayById = (id) => core.get(`/times_of_day/${id}`);
const saveTimesOfDay = (data) => core.post(`/times_of_day`, data);
// const fetchPlaylistPackTypes = () => core.get("/playlist_pack_types");
const fetchPlaylistPackTypes = (ps, offset, sort, status) =>
  getList(core, `/playlist_pack_types`, { ps, offset, sort, status });
const getNotificationTemplate = (id) => core.get(`/notification_templates/${id}`);
const saveNotificationTemplate = (nt) => core.post(`/notification_templates`, nt);
const fetchRights = () => core.get("/rights");
const getContentTypeById = (id) => core.get(`/content_types/${id}`);
const saveContentType = (data) => core.post(`/content_types`, data);
const saveSegmentType = (segmentType) => core.post(`/segment_types`, segmentType);
const getSegmentTypeById = (id) => core.get(`/segment_types/${id}`);
const syncCertificateWithMovieBuff = (filter) => core.post(`/certifications/sync`, filter);
const fetchCertificate = (certificateId) => core.get(`/certifications/${certificateId}`);
const getCertificationsByCountry = (countryId) => core.get(`/countries/${countryId}/certification`);
const getCertificationById = (countryId, contentTypeId) =>
  core.get(`/countries/${countryId}/certification/${contentTypeId}`);
const saveCountryCertificate = (countryId, certificate) =>
  core.post(`/countries/${countryId}/certification`, certificate);
const getActiveCountriesCount = () => core.get("/countries/active/count");
const saveCampaignTargetType = (data) => core.post(`/campaign_target_types`, data);
const fetchCampaignTargetTypes = (filter) =>
  core.get(`/campaign_target_types?${createQueryParams(filter)}`);
const getCampaignTargetType = (id) => core.get(`/campaign_target_types/${id}`);
const getCategory = (id) => core.get(`/categories/${id}`);
const saveCategory = (category) => core.post(`/categories`, category);
const searchCategories = (text, type) => {
  const qp = createQueryParams({ text, type });
  return core.get(`/categories?${qp}`);
};
const fetchBusinessTypes = () => core.get(`brands/brand_types`);
const updateAdvertiserType = (advertiserType) => core.post(`buyers/types`, advertiserType);
const fetchAdvertiserTypes = (sort) => core.get(`buyers/types?sort=${sort}`);
const fetchAdvertiserTypesById = (id) => core.get(`buyers/types/${id}`);
const categorySearchWithText = (text) => core.get(`/categories?text=${text}`);
const categorySearchWithBrandAndText = (brandId, text) =>
  core.get(`/categories?text=${text}&brandId=${brandId}`);
const getProductIdentificationNumberType = (id) =>
  core.get(`/product_identification_number_types/${id}`);
const saveProductIdentificationNumberType = (productIdentificationNumberType) =>
  core.post(`/product_identification_number_types`, productIdentificationNumberType);
const fetchCountry = (countryId) => core.get(`/countries/${countryId}`);
const fetchCountryTaxes = (countryId) => core.get(`/countries/${countryId}/taxes`);
const fetchTaxById = (countryId, taxId) => core.get(`/countries/${countryId}/taxes/${taxId}`);
const saveTax = (countryId, reqPay) => core.post(`/countries/${countryId}/taxes`, reqPay);
const syncCountriesWithQubeCinemas = () => core.post(`/countries/sync`);
const getRolesScopes = () => rolesScopes.get("slate_user_scopes.json");

// Theatres/Screens
const getAdminScreens = (ps, offset, sort, filter) =>
  getList(core, `/screens`, { ps, offset, sort, filter });
const getUserScreens = (ps, offset, sort, filter, companyId) =>
  getList(core, `/companies/${companyId}/screens`, { ps, offset, sort, filter });
// const getUserScreensByIds = (companyId, ids) =>
//   core.post(`/companies/${companyId}/screens/ids/screenId/_list`, { ids });
const getUserScreensByIds = (ids, campaignId) =>
  core.post(`/target_groups/ids/screenId/_list`, { ids, campaignId });
const getScreenPlaylist = (id, ps, offset, sort, status) =>
  getList(mockServer, `/screens/${id}/playlist_templates`, { ps, offset, sort, status });
const getTheatrePlaylist = (id, ps, offset, sort, status) =>
  getList(mockServer, `/theatres/${id}/playlist_templates`, { ps, offset, sort, status });
const getAdminTheatres = (ps, offset, sort, filter) =>
  getList(core, `/theatres`, { ps, offset, sort, filter });

// mockServer.get(`/theatresInitialdata`, {
//   baseURL: MOCK_SERVER_URL,
// });
//
const getUserTheatres = (ps, offset, sort, filter, companyId) =>
  getList(core, `/companies/${companyId}/theatres`, { ps, offset, sort, filter });
// const getUserTheatresByIds = (companyId, ids) =>
//   core.post(`/companies/${companyId}/theatres/ids/theatreId/_list`, { ids });
const getUserTheatresByIds = (ids, campaignId) =>
  core.post(`/target_groups/ids/theatreId/_list`, { ids, campaignId });
const getTheatreScreens = (ps, offset, sort, filter) =>
  getList(mockServer, `/theatre_screens`, { ps, offset, sort, filter });
const getTheatreRights = (id, ps, offset, sort, filter) =>
  getList(core, `/theatres/${id}/rights`, { ps, offset, sort, filter });
const getScreenRights = (id, ps, offset, sort, filter) =>
  getList(core, `/screens/${id}/rights`, { ps, offset, sort, filter });
const getTheatreRateCards = (ps, offset, sort, filter) =>
  getList(mockServer, `/rate_cards/theatres`, { ps, offset, sort, filter });
const getTheatreSpots = (theatreId, ps, offset, sort, filter) =>
  getList(core, `/theatres/${theatreId}/spot_status`, { ps, offset, sort, filter });
const getScreenSpots = (screenId, ps, offset, sort, filter) =>
  getList(core, `/screens/${screenId}/spot_status`, { ps, offset, sort, filter });
const getScreenRateCards = (ps, offset, sort, filter) =>
  getList(mockServer, `/rate_cards/screens`, { ps, offset, sort, filter });
const getScreenSettings = (screenId) => core.get(`/screens/${screenId}/settings`);
const createOrUpdateScreenScheduleTimings = (companyId, data) =>
  core.post(`/companies/${companyId}/scheduling_timings`, data);
const createOrUpdateSettings = (companyId, data) =>
  core.post(`/companies/${companyId}/screens/settings`, data);
const updateScreenStatus = (companyId, data) =>
  core.post(`/companies/${companyId}/screens/status`, data);
const getScreen = (screenId) => core.get(`/screens/${screenId}`);
const getTheatre = (theatreId) => core.get(`/theatres/${theatreId}`);
const getTheatreRight = (id) => mockServer.get(`/rights_theatres/${id}`);
const getScreenRight = (id) => mockServer.get(`/rights_screens/${id}`);
const createOrUpdateTheatreRight = (right) => new Promise((resolve, reject) => resolve(right));
const createOrUpdateScreenRight = (right) => new Promise((resolve, reject) => resolve(right));
const getUserScreenListByFilterId = (companyId, idType, data) =>
  core.post(`/companies/${companyId}/screens/ids/${idType}/_list`, data);

// Movies
const getMovies = (ps, offset, sort, filter) =>
  getList(core, `/movies`, { ps, offset, sort, filter });
const getMovie = (id) => core.get(`/movies/${id}`);
const getMovieScreens = (movieId, ps, offset, sort, filter) =>
  getList(core, `/movies/${movieId}/screens`, { ps, offset, sort, filter });
const getMovieTheatres = (movieId, ps, offset, sort, filter) =>
  getList(core, `/movies/${movieId}/theatres`, { ps, offset, sort, filter });

// Content
const getCompanyContent = (ps, offset, sort, archived) =>
  mockServer.get(`/content/_list?${createQueryParams({ ps, offset, sort, archived })}`);
const getContentById = (id) => mockServer.get(`/content/${id}`);
const updateContentDetails = (data) =>
  new Promise((resolve) => resolve({ data: "Brand Mapped Successfully" }));
const archiveContent = (data) => core.post("/archived-content", data);
const unArchiveContent = (data) => core.post("/content/unarchive", data);

// Brands
const getParentBrands = (ps, offset, sort, filter) =>
  getList(core, `/brands/parent_brands`, { ps, offset, sort, filter });
const getIndividualBrands = (ps, offset, sort, filter) =>
  getList(core, `/brands/individual_brands`, { ps, offset, sort, filter });
const getProducts = (ps, offset, sort, filter) =>
  getList(core, `/brands/products`, { ps, offset, sort, filter });
const getVariants = (ps, offset, sort, filter) =>
  getList(core, `/brands/variants`, { ps, offset, sort, filter });
const brandContent = (brandId, ps, offset, sort, filter) =>
  getList(mockServer, `/brands/${brandId}/content`, { ps, offset, sort, filter });
const getParentBrandById = (id) => core.get(`/brands/parent_brands/${id}`);
const getIndividualBrandById = (id) => core.get(`/brands/individual_brands/${id}`);
const getProductById = (id) => core.get(`/brands/products/${id}`);
const getVariantById = (id) => core.get(`/brands/variants/${id}`);
const saveParentBrand = (data) => core.post("/brands/parent_brands", data);
const saveIndividualBrand = (data) => core.post("/brands/individual_brands", data);
const saveProduct = (data) => core.post("/brands/products", data);
const saveVariant = (data) => core.post("/brands/variants", data);
const searchBrands = (q, brandType, businessType) =>
  core.get(`/brands?${queryString.stringify({ q, brandType, businessType })}`);
const searchBrandsWithAdvertiserId = ({ q, advertiserId, brandType, businessType }) =>
  core.get(`/brands?q=${q}&advertiserId=${advertiserId}`);
const searchParentBrands = (text, type) =>
  core.get(`/brands/parent_brands?${queryString.stringify({ text, type })}`);
const searchIndividualBrands = (text, parentBrandId) =>
  core.get(`/brands/individual_brands?${queryString.stringify({ text, parentBrandId })}`);
const searchProducts = (text, individualBrandId) =>
  core.get(`/brands/products?${queryString.stringify({ text, individualBrandId })}`);
const _getConflictingBrands = (path, source, target, company_id) =>
  core.get(
    `brands/${path}/conflicts?${queryString.stringify({
      source,
      target,
      company_id,
    })}`
  );
const getConflictingIndividualBrands = partial(_getConflictingBrands, "individual_brands");
const getConflictingProducts = partial(_getConflictingBrands, "products");
const getConflictingVariants = partial(_getConflictingBrands, "variants");
const _searchDuplicateBrands = (path, text, id, companyId, businessType, ps, offset) =>
  core.get(
    `/brands/${path}/duplicates?${queryString.stringify({
      text,
      id,
      companyId,
      businessType,
      ps,
      offset,
    })}`
  );
const searchDuplicateParentBrands = partial(_searchDuplicateBrands, "parent_brands");
const searchDuplicateIndividualBrands = partial(_searchDuplicateBrands, "individual_brands");
const searchDuplicateProducts = partial(_searchDuplicateBrands, "products");
const searchDuplicateVariants = partial(_searchDuplicateBrands, "variants");

// Brand Verifications
const submitBrandVerification = (data) => core.post("brand_verifications", data);
const getBrandVerifications = (ps, offset, sort, filter) =>
  getList(core, `/brand_verifications/pending`, { ps, offset, sort, filter });
const getBrandVerification = (parentBrandId, companyId) =>
  core.get(`/brand_verifications/pending/${parentBrandId}/${companyId}`);
const getRejectedBrandVerifications = (ps, offset, sort, filter) =>
  getList(core, `/brand_verifications/rejected`, { ps, offset, sort, filter });
const getRejectedBrandVerification = (brandType, draftId) =>
  core.get(`brand_verifications/rejected/${brandType}/${draftId}`);

// Companies
const getMediaAgencies = (ps, offset, sort, filter, status = "Approved") =>
  getList(core, "/companies/media_agency", { ps, offset, sort, filter, status });
const getExhibitors = (ps, offset, sort, filter) =>
  getList(mockServer, "/companies/exhibitors", { ps, offset, sort, filter });
const getCompaniesList = (type = "company") => getTagListByType({ type });
const saveCompanyPreferences = (data, companyId) =>
  core.post(`/companies/${companyId}/preferences`, data);
const getCompanyById = (id) => core.get(`/companies/${id}`);
const getThirdpartyIdentifier = () => mockServer.post(`/thirdparty_identifiers/_list`);

// Advertisers
const createOrUpdateAdvertiser = (advertiser) => core.post(`/buyers`, advertiser);
const getAdvertisers = (ps, offset, sort, filter) =>
  getList(core, `/buyers`, { ps, offset, sort, filter });
const searchAdvertisers = (q) => core.get(`/buyers?${createQueryParams({ q })}`);
const getCompanyAdvertiserPreferences = (companyId, advertiserId) =>
  core.get(`/companies/${companyId}/buyers/${advertiserId}/preferences`);
const saveAdvertiserPreferences = (data, companyId, advertiserId) =>
  core.post(`/companies/${companyId}/buyers/${advertiserId}/preferences`, data);
const getAdvertiserById = (id) => core.get(`/buyers/${id}`);

// Unmapped CPLs
const getCPLs = (status, ps, offset, sort, filter) =>
  getList(core, `/cpl_mappings`, { ps, offset, sort, status, filter });
const getMovieCPLs = (_, ps, offset, sort, filter) =>
  getList(core, "/cpl_mappings/movies", { ps, offset, sort, filter });
const ignoreCPL = (id) => core.post(`/cpls/${id}/ignore`);
const getCPLById = (id) => core.get(`/cpl_mappings/${id}`);
const mapCPL = (id, data) => core.post(`/cpls/${id}/map`, data);

// Playlist Templates
const assignPlaylistTemplate = () => new Promise((resolve) => resolve({}));
const fetchPlaylistTemplatesList = (ps, offset, sort, filter) =>
  getList(core, `/playlist_templates`, { ps, offset, sort, filter });
const getPlaylistTemplatesById = (id) => core.get(`/playlist_templates/${id}`);
const searchPlaylistTemplate = (query, ps, offset, sort, filter) =>
  getList(core, `/playlist_templates`, { query, ps, offset, sort, filter });

// Tags
const fetchTagTypes = () => core.get(`/tags/types`);
const getTagListByType = (params) => getList(core, `/tags`, params);
const getTagByID = (tagId) => core.get(`/tags/${tagId}`);
const searchTags = (q, type, currentFilters) => {
  const filterParams = JSON.stringify(currentFilters);
  const qp = createQueryParams({ q, type, currentFilter: filterParams });
  return core.get(`/tags?${qp}`);
};

// Reports
let ReportsServer = axios.create({
  baseURL: REPORTS_BASE_URL,
  timeout: 600000,
});

const getReports = () => ReportsServer.post("/reports/_list");

const getScheduledReports = () => ReportsServer.post("/scheduled_reports/_list");

const downloadReport = (id, filters) =>
  ReportsServer.post(
    `/reports/${id}/generate`,
    {
      filters,
    },
    { responseType: "blob" }
  );
// Moviebuff
const searchMovies = (query) => {
  const qp = createQueryParams({ query });
  return moviebuff.get(`/search/movie?${qp}`);
};
const searchLocation = (query, countryCodes, type = "all") => {
  const queryParams = { query, "filters[country_code]": countryCodes?.map((c) => c) };
  const qp = queryString.stringify(queryParams, { arrayFormat: "none" });
  return moviebuff.get(`/search/locations/${type}?${qp}`);
};

// Schedules
const getSchedules = (ps, offset, sort, filter) =>
  getList(core, "/schedules", { ps, offset, sort, filter });
const getScheduleById = (scheduleId) => core.get(`/schedules/${scheduleId}`);
const getScheduleScreens = (scheduleId, ps, offset, sort, filter) =>
  getList(core, `/schedules/${scheduleId}/screens`, { ps, offset, sort, filter });
const createOrUpdateSchedules = (data) => core.post("/schedules", data);
const createOrUpdateScheduleScreen = (scheduleId, data) =>
  core.post(`/schedules/${scheduleId}/screens`, data);
// Campaign Lists
const getCampaigns = (ps, offset, sort, filter) =>
  getList(core, "/campaigns", { ps, offset, sort, filter });
const getRejectedProposals = (ps, offset, sort, filter) =>
  getList(core, "/campaigns", { ps, offset, sort, filter, campaignStatus: "Rejected" });
const getCampaignTargetGroups = (campaignId, ps, offset, sort, filter) =>
  getList(core, `/campaigns/${campaignId}/target_groups`, { ps, offset, sort, filter });
const getCampaignMedia = (campaignId, ps, offset, sort, filter) =>
  getList(core, `/campaigns/${campaignId}/media`, { ps, offset, sort, filter });
const getTargetGroupMedia = (targetGroupId, ps, offset, sort, filter) =>
  getList(core, `/target_groups/${targetGroupId}/media`, { ps, offset, sort, filter });
const getCampaignApprovals = (ps, offset, sort, filter) =>
  getList(core, "/campaigns", { ps, offset, sort, filter, campaignStatus: "Proposed" });
const getCampaignSpots = (campaignId, ps, offset, sort, filter) =>
  getList(core, `/campaigns/${campaignId}/spot_status`, { ps, offset, sort, filter });

// Campaign Metadata
const searchCurrencies = (query) => {
  const qp = createQueryParams({ q: query });
  return core.get(`/currencies?${qp}`);
};
const getTheatreTypes = (query, ps, offset, sort, filter) =>
  getList(core, `/theatre_types`, { ps, offset, sort, filter });
const getTheatreLocationTypes = (query, ps, offset, sort, filter) =>
  getList(core, `/theatre_location_types`, { ps, offset, sort, filter });
const getDigitalIntegrators = (query, ps, offset, sort, filter) =>
  getList(core, `/digital_integrators`, { ps, offset, sort, filter });
const getProjectionTypes = (query, ps, offset, sort, filter) =>
  getList(core, `/projection_types`, { ps, offset, sort, filter });
const getCoolingTypes = (query, ps, offset, sort, filter) =>
  getList(core, `/cooling_types`, { ps, offset, sort, filter });
const getExperiences = (query, ps, offset, sort, filter) =>
  getList(core, `/experiences`, { ps, offset, sort, filter });
const getLanguages = () => mockServer.get("/languages");
const typeOfContent = () => mockServer.get("/typeOfContent");
const pictureTypes = () => mockServer.get("/pictureTypes");
const aspectRatios = () => mockServer.get("/aspectRatios");
const audioTypes = () => mockServer.get("/audioTypes");
const resolutionTypes = () => mockServer.get("/resolutionTypes");

// Campaign Create/Update
// TODO: Update url once actual API is completed
const createOrUpdateCampaign = (data) => core.post("/campaigns", data);
const createOrUpdateTargetGroup = (campaignId, data) =>
  //mockServer.post(`/campaigns/${campaignId}/target_groups/create`, data);
  core.post(`/campaigns/${campaignId}/target_groups`, data);
const createOrUpdateMedia = (campaignId, targetGroupId, data) =>
  core.post(`/campaigns/${campaignId}/target_groups/${targetGroupId}/media`, data);

// Campaign Status Update
const setCampaignStatus = (campaignId, status) =>
  core.post(`/campaigns/${campaignId}/update_status`, { status });
// Campaign Delete
// TODO: Update url once actual API is completed
const deleteCampaign = (id) => core.delete(`/campaigns/${id}`);
const deleteTargetGroup = (id) => core.delete(`/target_groups/${id}`);
const deleteMedia = (id) => core.delete(`/media/${id}`);

// Campaign Pause/Resume
const pauseOrResumeCampaigns = (data, status) => core.post(`campaigns/status/${status}`, data);
const pauseOrResumeTargetGroup = (data, status) =>
  core.post(`/target_groups/status/${status}`, data);
const pauseOrResumeScreens = (campaignId, data, status) =>
  core.post(`/campaigns/${campaignId}/screens/${status}`, data);
const pauseOrResumeMedia = (data, status) => core.post(`/media/status/${status}`, data);

// Campaign Cancel
const cancelCampaigns = (data) => core.post(`/campaigns/status/cancel`, data);
const cancelTargetGroups = (data) => core.post(`/target_groups/status/cancel`, data);
const cancelScreens = (campaignId, data) =>
  core.post(`/campaigns/${campaignId}/screens/cancel`, data);
const cancelMedia = (data) => core.post(`/media/status/cancel`, data);

// Campaign Move
const moveMedia = (data) => core.post(`/media/move`, data);
const moveTargetGroup = (data) => core.post(`/target_groups/move`, data);
const moveCampaign = (data) => core.post(`/campaigns/move`, data);

// Target Group Split
const targetGroupSplit = (id, data) => core.post(`/target_groups/${id}/split`, data);

// Campaign Details
const getCampaign = (campaignId) => core.get(`/campaigns/${campaignId}`);
const getCampaignFiles = (campaignId) => mockServer.get(`/campaigns/${campaignId}/files`);
const getCampaignFileUrl = (id, file_name, is_download) =>
  mockServer.get(`/dropbox/campaigns?${createQueryParams({ id, file_name, is_download })}`);
const getCampaignSummary = (campaignId) => core.get(`/campaigns/${campaignId}/summary`);
const getCompleteCampaignSummary = (campaignId) =>
  core.get(`/campaigns/${campaignId}/summary_data`);
const getCampaignTheatres = (campaignId, ps, offset, sort, filter) =>
  getList(core, `/campaigns/${campaignId}/theatres`, { ps, offset, sort, filter });
const getCampaignScreens = (campaignId, ps, offset, sort, filter) =>
  getList(core, `/campaigns/${campaignId}/screens`, { ps, offset, sort, filter });
const getTargetGroupTheatres = (targetGroupId, ps, offset, sort, filter) =>
  getList(core, `/target_groups/${targetGroupId}/theatres`, {
    ps,
    offset,
    sort,
    filter,
  });
const getSpotAvailability = (targetGroupId, ps, offset, sort, filter) =>
  getList(core, `/target_groups/${targetGroupId}/spot_availablity`, {
    ps,
    offset,
    sort,
    filter,
  });
const getTargetGroupScreens = (targetGroupId, ps, offset, sort, filter) =>
  getList(core, `/target_groups/${targetGroupId}/screens`, { ps, offset, sort, filter });
const getMediaTheatres = (mediaId, ps, offset, sort, filter) =>
  getList(core, `/media/${mediaId}/theatres`, { ps, offset, sort, filter });
const getMediaScreens = (mediaId, ps, offset, sort, filter) =>
  getList(core, `/media/${mediaId}/screens`, { ps, offset, sort, filter });
const getTargetGroup = (targetGroupId) => core.get(`/target_groups/${targetGroupId}`);
const getTargetGroupSummary = (targetGroupId) =>
  core.get(`/target_groups/${targetGroupId}/summary`);
const getTargetGroupSpots = (targetGroupId, ps, offset, sort, filter) =>
  getList(core, `/target_groups/${targetGroupId}/spot_status`, { ps, offset, sort, filter });
const getMedia = (mediaId) => core.get(`/media/${mediaId}`);
const getMediaSummary = (mediaId) => core.get(`/media/${mediaId}/summary`);
const getMediaSpots = (mediaId, ps, offset, sort, filter) =>
  getList(core, `/media/${mediaId}/spot_status`, { ps, offset, sort, filter });
const getTheatresByCriteria = (criteriaObject, ps, offset, sort, filter) =>
  // getList(mockServer, `/target_groups/789n6-gc2theatres`, {
  //   ps,
  //   offset,
  //   sort,
  //   filter,
  // });
  //core.post(`/target_groups/criteria/theatres/_list`, criteriaObject);
  getLists(core, `/target_groups/criteria/theatres`, criteriaObject, {
    ps,
    offset,
    sort,
    filter,
  });

// Campaign Approval
const approveCampaign = (campaignId, approvalNotes) =>
  core.post(`/campaigns/${campaignId}/status/approve`, { comments: approvalNotes });
const requestChangesCampaign = (campaignId, requestedChange) =>
  core.post(`/campaigns/${campaignId}/request_change`, { requestedChange });
const rejectCampaign = (campaignId, reasonId, comments) =>
  core.post(`/campaigns/${campaignId}/status/reject`, { reasonId, comments });
// Add fulfillment APIs

/*
1.The code defines two functions called getFailure and getShowFailureSchedule.
2.Both functions take in some parameters and return a call to another function called getList.
3.getList is passed three arguments: core, a string path, and an object with query parameters.
4.The getList function likely makes an HTTP request to the server with the given arguments and returns a Promise with the response data.
*/
const getFailure = (ps, offset, sort, filter) =>
  getList(core, "/show_failures", { ps, offset, sort, filter });
const getShowFailureSchedule = (id, ps, offset, sort, filter) =>
  getList(core, `/show_failures/screens/${id}/schedules`, { ps, offset, sort, filter });

const getShowFailureSpotStatus = (id, ps, offset, sort, filter) =>
  getList(core, `/show_failures/screens/${id}/spot_status`, { ps, offset, sort, filter });

// Campaign Bias
const getCampaignBias = (ps, offset, sort, filter) =>
  getList(core, "/campaigns/effective_rates", { ps, offset, sort, filter });

const updateCampaignBias = (campaignId, data) =>
  core.post(`/campaigns/${campaignId}/update_bias`, data);

// Compositions
const getCompositions = (ps, offset, sort, filter) =>
  getList(core, `/cpls`, { ps, offset, sort, filter });
const searchCompositions = (q) => getList(core, "/cpls", { q });
const uploadComposition = (data) =>
  core.post("/cpls", data, {
    headers: { "Content-Type": "application/xml" },
  });
const getCompositionById = (id) => core.get(`/cpls/${id}`);
const updateComposition = (data, id) => core.post(`/cpls/${id}`, data);

// Upload CSV QW
const uploadCSV = (data, config) => qw_csv.post("/facility/search", data, config);

const getCore = () => core;

export {
  getCore,
  intialiseCore,
  validateToken,
  getLogsByRef,
  getLogsBySource,
  fetchPlaylistPackTypes,
  getNavCount,
  searchCertificates,
  getLanguages,
  typeOfContent,
  pictureTypes,
  aspectRatios,
  audioTypes,
  resolutionTypes,
  fetchBillingCycles,
  fetchOrderRegions,
  syncCertificateWithMovieBuff,
  fetchCertificate,
  getNotificationTemplate,
  searchNotificationTemplates,
  saveNotificationTemplate,
  searchContentTypes,
  getContentTypeById,
  saveContentType,
  getReasonsList,
  getAllReasonsByType,
  fetchReasonTypes,
  createReason,
  updateReason,
  getReasonById,
  searchCountries,
  getActiveCountriesCount,
  searchSegmentTypes,
  saveSegmentType,
  getSegmentTypeById,
  getCertificationsByCountry,
  getCertificationById,
  saveCountryCertificate,
  fetchRights,
  fetchCampaignTargetTypes,
  getCampaignTargetType,
  saveCampaignTargetType,
  getTimesOfDay,
  saveTimesOfDay,
  getTimesOfDayById,
  getParentBrands,
  getIndividualBrands,
  getProducts,
  getVariants,
  brandContent,
  getParentBrandById,
  getIndividualBrandById,
  getProductById,
  getVariantById,
  getCategoryLists,
  getCategory,
  saveCategory,
  fetchTagTypes,
  getTagByID,
  searchTags,
  getTagListByType,
  fetchBusinessTypes,
  fetchMediaList,
  getAdvertiserById,
  saveParentBrand,
  saveIndividualBrand,
  saveProduct,
  saveVariant,
  fetchProductIdentificationNumberTypeList,
  getProductIdentificationNumberType,
  saveProductIdentificationNumberType,
  updateAdvertiserType,
  fetchAdvertiserTypes,
  fetchAdvertiserTypesById,
  categorySearchWithText,
  categorySearchWithBrandAndText,
  fetchCountry,
  fetchCountryTaxes,
  fetchTaxById,
  saveTax,
  syncCountriesWithQubeCinemas,
  searchMovies,
  getRolesScopes,
  getAdminTheatres,
  getUserTheatres,
  getUserTheatresByIds,
  getAdminScreens,
  getUserScreens,
  getUserScreensByIds,
  getScreen,
  getTheatre,
  getTheatreScreens,
  getTheatrePlaylist,
  getScreenPlaylist,
  getMovies,
  getMovie,
  getMovieScreens,
  getMovieTheatres,
  getTheatreRights,
  getScreenRights,
  getTheatreRight,
  getScreenRight,
  getTheatreRateCards,
  getScreenRateCards,
  getCompaniesList,
  createOrUpdateTheatreRight,
  createOrUpdateScreenRight,
  getAdvertisers,
  createOrUpdateAdvertiser,
  getCompanyContent,
  getMediaAgencies,
  getExhibitors,
  getCompanyById,
  searchLocation,
  assignPlaylistTemplate,
  fetchPlaylistTemplatesList,
  searchPlaylistTemplate,
  getCPLs,
  ignoreCPL,
  getCPLById,
  mapCPL,
  getMovieCPLs,
  searchAdvertisers,
  searchCategories,
  searchBrands,
  searchBrandsWithAdvertiserId,
  searchParentBrands,
  searchIndividualBrands,
  searchProducts,
  getBrandVerifications,
  getBrandVerification,
  getRejectedBrandVerifications,
  getRejectedBrandVerification,
  searchDuplicateParentBrands,
  searchCurrencies,
  saveAdvertiserPreferences,
  saveCompanyPreferences,
  getCompanyAdvertiserPreferences,
  getCampaigns,
  getCampaignFiles,
  getCampaignFileUrl,
  getRejectedProposals,
  getCampaign,
  getCampaignSummary,
  getCompleteCampaignSummary,
  getCampaignTheatres,
  pauseOrResumeCampaigns,
  pauseOrResumeTargetGroup,
  pauseOrResumeMedia,
  pauseOrResumeScreens,
  cancelCampaigns,
  cancelTargetGroups,
  cancelScreens,
  cancelMedia,
  getTargetGroupTheatres,
  getMediaTheatres,
  getCampaignScreens,
  getTargetGroupScreens,
  getMediaScreens,
  getCampaignTargetGroups,
  getCampaignMedia,
  getTargetGroupMedia,
  getTargetGroup,
  getTargetGroupSummary,
  getMedia,
  getMediaSummary,
  getCampaignApprovals,
  searchDuplicateIndividualBrands,
  searchDuplicateProducts,
  searchDuplicateVariants,
  getConflictingIndividualBrands,
  getConflictingProducts,
  getConflictingVariants,
  submitBrandVerification,
  getScreenSettings,
  createOrUpdateScreenScheduleTimings,
  getReports,
  getScheduledReports,
  downloadReport,
  approveCampaign,
  getThirdpartyIdentifier,
  requestChangesCampaign,
  rejectCampaign,
  createOrUpdateSettings,
  updateScreenStatus,
  getCampaignTree,
  getContentById,
  updateContentDetails,
  archiveContent,
  unArchiveContent,
  getBrandById,
  getTheatreTypes,
  getTheatreLocationTypes,
  getDigitalIntegrators,
  getProjectionTypes,
  getCoolingTypes,
  getExperiences,
  getTheatresByCriteria,
  createOrUpdateCampaign,
  createOrUpdateTargetGroup,
  createOrUpdateMedia,
  deleteCampaign,
  deleteTargetGroup,
  deleteMedia,
  getCompositions,
  searchCompositions,
  uploadComposition,
  getCompositionById,
  updateComposition,
  getSchedules,
  getFailure,
  getCampaignBias,
  updateCampaignBias,
  getShowFailureSchedule,
  getShowFailureSpotStatus,
  getScheduleById,
  getScheduleScreens,
  createOrUpdateSchedules,
  createOrUpdateScheduleScreen,
  getPlaylistTemplatesById,
  getUserScreenListByFilterId,
  setCampaignStatus,
  searchCpls,
  moveTargetGroup,
  moveCampaign,
  moveMedia,
  targetGroupSplit,
  getSpotAvailability,
  getCampaignSpots,
  getTargetGroupSpots,
  getMediaSpots,
  getTheatreSpots,
  getScreenSpots,
  uploadCSV,
};
